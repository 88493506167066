<body>
background-color: #f5f5f5; /* 将 #f5f5f5 替换为你想要设置的颜色 */
</body>
<template>
  <div class="app-container">
    <el-form :model="queryParams" :rules="searchRules" ref="queryForm" :inline="true" size="mini" label-width="68px">
      <el-form-item label="客户昵称" prop="orderNo">
        <el-input v-model="queryParams.userName" placeholder="请输入昵称" clearable/>
      </el-form-item>

      <el-form-item label="备注名称" prop="opporId">
        <el-input v-model="queryParams.remark" placeholder="请输入名称" clearable/>
      </el-form-item>


      <!--      <el-form-item label="企微账号" prop="payAccount">-->
      <!--        <el-select v-model="queryParams.payAccount" size="mini" :clearable="true">-->
      <!--          <el-option v-for="item in payAccountArr" :key="item.id" :value="item.dictValue"-->
      <!--                     :label="item.dictLabel"></el-option>-->
      <!--        </el-select>-->
      <!--      </el-form-item>-->

      <el-form-item label="使用员工" prop="userIds">
        <commonSelect v-model="queryParams.emplId" showCheckedTagDiv="hide"
                      mode="选择员工"/>
      </el-form-item>


      <el-form-item label="企微标签" prop="payAccount">
        <!--                <el-select v-model="queryParams.corpTag" size="mini" :clearable="true">-->
        <!--                    <el-option v-for="item in payAccountArr" :key="item.id" :value="item.dictValue"-->
        <!--                               :label="item.dictLabel"></el-option>-->
        <!--                </el-select>-->
        <commonSelect v-model="queryParams.corpTag"
                      showCheckedTagDiv="hide"
                      mode="选择标签"/>
      </el-form-item>
      <el-form-item label="个人标签" prop="payAccount">
        <el-select   filterable      multiple  collapse-tags   v-model="queryParams.corpPersonTagArr" :clearable="true">
          <el-option v-for="item in personTagData" :key="item.id" :value="item.tagName"
                     :label="item.tagName"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="流失状态" prop="status">
        <el-select v-model="queryParams.status" :clearable="true">
          <el-option value="0"
                     label="未流失"></el-option>
          <el-option value="1"
                     label="已流失"></el-option>
        </el-select>
      </el-form-item>
      <!--      <el-form-item label="所属咨询" prop="payAccount">-->
      <!--        <el-select v-model="queryParams.payAccount" size="mini" :clearable="true">-->
      <!--          <el-option v-for="item in payAccountArr" :key="item.id" :value="item.dictValue"-->
      <!--                     :label="item.dictLabel"></el-option>-->
      <!--        </el-select>-->
      <!--      </el-form-item>-->
      <!--      <el-form-item label="学院状态" prop="payAccount">-->
      <!--        <el-select v-model="queryParams.payAccount" size="mini" :clearable="true">-->
      <!--          <el-option v-for="item in payAccountArr" :key="item.id" :value="item.dictValue"-->
      <!--                     :label="item.dictLabel"></el-option>-->
      <!--        </el-select>-->
      <!--      </el-form-item>-->
      <el-form-item label="手机号" prop="remarkMobiles">
        <el-input v-model="queryParams.remarkMobiles" placeholder="请输入手机号" clearable/>
      </el-form-item>
      <el-form-item label="unionID" prop="unionid">
        <el-input v-model="queryParams.unionid" placeholder="请输入unionID" clearable/>
      </el-form-item>

      <el-form-item label="创建时间">
        <el-date-picker
            v-model="createTime"
            type="datetimerange"
            align="right"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            @change="createTimeChange"
        >
        </el-date-picker>
      </el-form-item>


      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">查询</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <div>
      <span class="move-down-span"> 客户总数{{ pageTotal }}:，去重后客户数{{ uniqueCount }}
      <el-button type="text" @click="syncCustomer"> <i
          class="el-icon-refresh"></i>{{ syncJobStatus }}</el-button></span>

      <div class="button-wrapper">
        <commonSelect v-model="batchEditTagValue"
                      showCheckedTagDiv="hide"
                      @batchEditTag="batchEditTag" buttonLabel="批量编辑标签" mode="选择标签"/>

      </div>
    </div>


    <el-table max-height="900" v-loading="loading" :data="repairList " style="width: 100%"
              @selection-change="handleSelectionChange" :row-class-name="tableRowClassName"
              :header-cell-style="{ backgroundColor: 'rgba(245,234,234,0)', color: 'rgba(197,195,195,0.47)', fontSize: '14px', textAlign: 'center', borderLeft: '0.5px F5EAEA00 solid', borderBottom: '1px F5EAEA00 solid' }">
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column label="unionid" align="center" prop="unionid" style="width: 100%"
                       column-key="unionIdColumn"/>
      <el-table-column label="客户名称" align="center" prop="userName" style="width: 100%"/>
      <el-table-column label="备注" align="center" prop="remark" style="width: 100%"/>
      <el-table-column v-slot="scope" label="性别" align="center" prop="gender" width="140" style="width: 100%">
        {{ scope.row.gender == 1 ? "男" : "女" }}
      </el-table-column>
      <el-table-column label="企微个人标签" align="center" prop="corpPersonTag" type="date" width="140"
                       style="width: 100%">
        <template v-slot="scope">
          <el-tag v-for="item in personTagName(scope.row)">{{ item }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="企微标签" align="center" prop="corpTag" type="date" width="140" style="width: 100%">
        <template v-slot="scope">
          <el-tag v-for="item in corpTagName(scope.row)">{{ item }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="企微号" align="center" prop="emplId" type="date" width="140" style="width: 100%">
        <template v-slot="scope">
          <el-tag>{{ emplIdName(scope.row.emplId) }}</el-tag>
        </template>
      </el-table-column>
      <!--      <el-table-column label="所属咨询师" align="center" prop="orderNo" type="date" width="140" style="width: 100%"/>-->
      <!--      <el-table-column label="学院状态" align="center" prop="orderNo" type="date" width="140" style="width: 100%"/>-->
      <el-table-column label="添加时间" align="center" prop="addTime" type="date" width="140" style="width: 100%"/>
      <el-table-column label="操作" align="center" type="date" width="140" style="width: 100%">
        <template v-slot="scope">
          <commonSelect v-model="scope.row.corpTag" v-if="scope.row.status === 0"
                        currentData="1" showCheckedTagDiv="hide"
                        buttonLabel="贴标签" @editTag="editTag(scope.row)" @editTagSend="editTagSend" mode="选择标签"/>
        </template>
      </el-table-column>

    </el-table>

    <pagination
        :pageSizes=[20,50,100]
        :v-show="queryParams.total>10"
        :total="queryParams.total"
        :page.sync="queryParams.page"
        :limit.sync="queryParams.size"
        @pagination="getList"
    />

    <OrderRepairDetail @getList="getList" :dialogValue.sync="repairShow" :repair-id="repairId" v-if="repairShow"
                       :repairStatus.sync="repairStatus"></OrderRepairDetail>
  </div>
</template>
<style>
.move-down-span {
  margin-bottom: 20px; /* 根据需要调整 */
}
</style>

<style scoped>

.move-down-span {
  margin-bottom: 100px; /* 根据需要调整 */
}

.button-wrapper {
  display: flex;
  justify-content: flex-end;

  .move-up-button {
    transform: translateY(-20px); /* 向上移动20像素 */
  }

}


</style>
<script>
import * as api from "@/utils/api";
import * as repairApi from "@/api/crm/orderRepair"
import * as weworkApi from "@/api/wework/weworkCustomer"
import OrderRepairDetail from "@/components/order/OrderRepairDetail.vue"
import {getUserId} from "@/utils/auth";
import * as  accountApi from "@/api/system/account";
import * as dectApi from '@/api/system/dictionary'
import SphereSendingAdd from "@/views/sphere/SphereSendingAdd.vue";
import commonSelect from "@/components/commonSelect/index.vue";
import {getLableList, loveCodeGroupUserList, getAllEmployeesList} from "@/components/commonSelect/api";
import {getCorpTag} from "../../api/wework/weworkCustomer";
import {batchEditTag, editTag} from "@/api/wework/weworkCustomer";

export default {
  name: "OrderRepair",
  components: {
    OrderRepairDetail,
    commonSelect
  },
  props: {},
  data() {
    return {
      editRow: {},
      currentTag: "",
      selectedRow: [],
      batchEditTagValue: "",
      syncJobStatus: "同步",
      userList: [],
      allUserList: [],
      tagList: {},
      createTime: [],
      corpTag: [],
      personTagData: [],
      withdraw: false,
      repairStatus: 0,
      repairId: 0,
      repairShow: false,
      uniqueCount: 0,
      pageTotal: 0,
      payTimeArr: [],
      queryParams: {
        goodsName: null,
        findType: 0,
        total: 0,
        current: 1,
        size: 10,
        page: 1,
        status: null,
        startDateTime: null,
        endDateTime: null,
      },
      repairList: [],
      loading: false,
      adviserList: [],
      accountList: [],
      possessList: [],
      searchRules: {
        opporId: [
          {min: 1, max: 9, message: '请输入正确的机会ID', trigger: 'blur'}
        ]
      },
      orderChannelList: [],
      collectionWayList: [],

      payAccountArr: [],
    };
  },
  created() {
    getAllEmployeesList().then(res => {
      this.loading = true;
      if (res.success) {
        this.allUserList = res.data;
        console.log(this.allUserList)
      } else {
        this.$message.error(res.msg)
      }
      this.getList()
    })

    // this.getList()
    // loveCodeGroupUserList().then(res => {
    //   this.loading = true
    //   if (res.success) {
    //     console.log(res)
    //     this.userList = res.data.deptUserList;
    //   } else {
    //     this.$message.error(res.msg)
    //   }
    //   this.getList()
    // })
    getCorpTag().then(res => {
      if (res.success) {
        this.tagList = res.data;
      } else {
        this.$message.error(res.msg)
      }
    })

    this.init();
    this.getPersonTagList();
    this.getAdviserList()
    this.getPossessList()
    this.getAccountList()

    dectApi.list({dictParent: 14}).then(resp => {
      if (resp.success) {
        this.payAccountArr = resp.data
      } else this.$message.error(resp.msg)
    })


  },
  computed: {
    SphereSendingAdd() {
      return SphereSendingAdd
    },
    userId: function () {
      return getUserId()
    },
    // 展示企微号的名称
    emplIdName(id) {
     return (id) =>{
       let res = "";
       for (let e of this.allUserList) {
         if(e.userId == id){
           res =  e.name;
         }
       }
       return res ;
     }
      // return (id) => {
      //   let result = "";
      //   let treeList = this.userList;
      //
      //   function flatten(tree = [], nodes = []) {
      //     for (let item of tree) {
      //       nodes.push(item)
      //       item.children && flatten(item.children, nodes)
      //     }
      //     return nodes
      //   }
      //
      //   const flatList = flatten(treeList)
      //   let ss = flatList.filter(i => i.isDept == 1)
      //   for (let a of ss) {
      //     if (a.id.split("_")[1] == id) {
      //       result = a.label
      //     }
      //   }
      //   return result
      // }
    },

    // 展示企微标签
    corpTagName(id) {
      return (id) => {
        let res = [];
        if (id.corpTag) {
          let idArr = id.corpTag.split(",")
          for (let i = 0; i < this.tagList.length; i++) {
            let _g = this.tagList[i];
            for (let j = 0; j < _g.tags.length; j++) {
              for (let k = 0; k < idArr.length; k++) {
                if (_g.tags[j].tagId == idArr[k]) {
                  res.push(_g.tags[j].tagName);
                }
              }
            }
          }
        }
        return res;
      }
    }
  },
  methods: {
    handleSelectionChange(val) {
      this.selectedRow = val;
      console.log(this.selectedRow);
    },
    editTag(val) {
      this.editRow = val;
    },
    editTagSend(val) {
      console.log(this.editRow);
      console.log(val)
      let body = {};
      body.tags = val;
      body.userId = this.editRow.userId;
      body.emplId = this.editRow.emplId;
      weworkApi.editTag(body).then(res => {
        if (res.success) {
          this.$message.success("编辑成功");
          this.batchEditTagValue = "";
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      })
    },
    batchEditTag() {
      if (this.selectedRow && this.selectedRow.length > 0 && this.batchEditTagValue) {
        console.log("===============batchEditTag")
        console.log(this.selectedRow);
        let body = {};
        body.tags = this.batchEditTagValue;
        let users = [];
        for (let i = 0; i < this.selectedRow.length; i++) {
          let user = {
            userId: this.selectedRow[i].userId,
            emplId: this.selectedRow[i].emplId
          }
          users.push(user)
        }
        body.users = users;
        console.log(body)
        weworkApi.batchEditTag(body).then(res => {
          if (res.success) {
            this.$message.success("编辑成功");
            this.batchEditTagValue = "";
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        })

      } else {
        this.$message.error("标签与勾选的用户不能为空");
        return
      }

    },
    personTagName(row) {
      let tag = row.corpPersonTag
      if (tag) {
        return tag.split(",")
      } else {
        return tag;
      }
    },
    getPersonTagList() {
      weworkApi.queryPersonTag().then(res => {
        console.log(res.data)
        if (res.success) {
          this.personTagData = res.data;
        } else {
          this.$message.error(res.msg);
        }
      })
    },
    createTimeChange(val) {
      if (this.createTime) {
        this.queryParams.startTime = this.createTime[0]
        this.queryParams.endTime = this.createTime[1]
      } else {
        this.queryParams.startTime = null
        this.queryParams.endTime = null
      }
    },
    syncCustomer() {
      this.syncJobStatus = "同步中....."
      weworkApi.syncCustomer().then(res => {
        if (res.success) {
          this.$message.success("同步成功");
        } else {
          this.$message.error(res.msg);
        }
        this.syncJobStatus = "同步"
      })
    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 1) {
        return 'warning-row';
      } else if (rowIndex % 2) {
        return 'success-row';
      }
      return 'warning-row';
    },
    // 初始化数据
    init() {

      dectApi.list({dictParent: 14}).then(resp => {
        if (resp.success) {
          this.payAccountArr = resp.data
        } else this.$message.error(resp.msg)
      })
      dectApi.list({dictParent: 15}).then(resp => {
        if (resp.success) {
          this.collectionWayList = resp.data
        } else this.$message.error(resp.msg)
      })
      dectApi.list({dictParent: 16}).then(resp => {
        if (resp.success) {
          this.orderChannelList = resp.data
        } else this.$message.error(resp.msg)
      })
    },
    repairInfo(repairId) {
      this.repairId = repairId
      this.repairStatus = 9;
      this.repairShow = true
    },
    /**
     * 修改录单
     */
    updateRepair(repairId) {
      this.repairId = repairId
      this.repairStatus = 1;
      this.repairShow = true
    },
    /**
     * 审核
     */
    examineRepair(repairId) {
      this.repairId = repairId
      this.repairStatus = 2;
      this.repairShow = true
    },
    /**
     * 提交
     */
    submitRepair(row) {
      repairApi.submitRepairId(row).then(res => {
        if (res.success) {
          this.$message.success(res.msg)
          this.getList();
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    /**
     * 撤回
     */
    backRepair(repairId) {
      repairApi.backRepair(repairId).then(res => {
        if (res.success) {
          this.$message.success(res.msg)
          this.getList();
        } else {
          this.$message.error(res.msg)
        }
      });
    },
    // 新增
    showDetail() {
      this.repairId = 0
      this.repairStatus = 0
      this.repairShow = true
    },
    //金额格式化
    numberformat(number) {
      number = (number + '').replace(/[^0-9+-Ee.]/g, '');
      var n = !isFinite(+number) ? 0 : +number,
          prec = !isFinite(+2) ? 0 : Math.abs(2),
          s = '',
          toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.floor(n * k) / k;
          };
      s = (prec ? toFixedFix(n, prec) : '' + Math.floor(n)).split('.');
      if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
      }
      return s.join('.');
    },
    /**
     * 获取唯一值配置
     */
    getPossessList() {
      api.possessList().then(res => {
        this.possessList = res.data
      })
    },
    /**
     * 获取学习顾问列表
     */
    getAdviserList: function () {
      accountApi.adviserList().then(res => {
        if (res.success) {
          this.adviserList = res.data
        }
      })
    },
    getAccountList: function () {
      accountApi.list().then(res => {
        if (res.success) {
          this.accountList = res.data
        }
      })
    },
    /** 查询参数配置列表 */
    getList() {
      this.loading = true
      console.log(this.queryParams)
      this.queryParams['submitId'] = this.submitId
      if(this.queryParams.corpPersonTagArr.length > 0){
        this.queryParams.corpPersonTag = this.queryParams.corpPersonTagArr.join()
      }else{
        this.queryParams.corpPersonTag = "";
      }

      weworkApi.customerList(this.queryParams).then(resp => {
        if (resp.success) {
          this.repairList = resp.data.res.records
          this.queryParams.total = resp.data.res.total
          this.queryParams.size = resp.data.res.size
          this.queryParams.current = resp.data.res.current
          this.pageTotal = resp.data.total
          this.uniqueCount = resp.data.uniqueCount
          this.loading = false
        } else {
          this.$message.error(resp.msg)
          this.queryParams.findType = 0
          // this.getList()
          this.loading = false
        }
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      // this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.payTimeArr = [];
      this.queryParams.userName = "";
      this.queryParams.remark = "";
      this.queryParams.emplId = "";
      this.queryParams.corpTag = "";
      this.queryParams.corpPersonTag = "";
      this.queryParams.corpPersonTagArr =[];
      this.queryParams.remarkMobiles = "";
      this.queryParams.unionid = "";
      this.queryParams.status = "";
      this.createTime = [],
          this.handleQuery();
    },
    deleteOrderRepair(orderRepairId) {
      repairApi.deleteOrderRepair(orderRepairId).then(res => {
        if (res.success) {
          this.$message.success(`删除成功!`);
          this.getList()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
  }
};
</script>
<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>

<style lang="less" scoped>
.small-padding button {
  margin: 3px;
}

</style>
